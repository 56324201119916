import {
  parseSupportedLanguageOrDefault,
  SupportedLanguage
} from '@pmi.web/localization';
import enUS from 'assets/i18n/en-US.json';
import { IS_PROD_BUILD } from 'constants/Env';
import i18next, { use, changeLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

const DEFAULT_NAMESPACE = 'pmi.web.registration';

export function initTranslations() {
  if (i18next.isInitialized || i18next.isInitializing) {
    return Promise.resolve();
  }

  return use(initReactI18next).init({
    /**
     * i18next options
     */
    debug: !IS_PROD_BUILD,
    fallbackLng: SupportedLanguage.English,
    ns: DEFAULT_NAMESPACE,
    resources: {
      [SupportedLanguage.English]: {
        [DEFAULT_NAMESPACE]: enUS
      }
    },
    keySeparator: false,
    nsSeparator: false,
    /**
     * react-i18next options
     */
    react: {
      bindI18n: 'languageChanged',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['h1', 'h2', 'p', 'ul', 'li', 'strong']
    }
  });
}

export async function onChangeLanguage(language: string) {
  const supportedLanguage = parseSupportedLanguageOrDefault(language);

  if (!i18next.hasResourceBundle(language, DEFAULT_NAMESPACE)) {
    const module = await import(`assets/i18n/${language}.json`);
    const translations = module.default;

    i18next.addResourceBundle(
      language,
      DEFAULT_NAMESPACE,
      translations,
      true,
      true
    );
  }

  changeLanguage(supportedLanguage);
}
